import { defineMessages } from 'react-intl';

export default {
  pages: {
    servicesAgreementUpdate: {
      ...defineMessages({
        title: {
          id: 'pages.servicesAgreementUpdate.title',
          defaultMessage: 'Service Agreement Update',
        },
        description: {
          id: 'pages.servicesAgreementUpdate.description',
          defaultMessage: 'Please read the Services Agreement carefully and accept it by ticking the checkbox.',
        },
        agreement: {
          id: 'pages.servicesAgreementUpdate.agreement',
          defaultMessage:
            'I, {name}, hereby certify that the provided information is accurate and that I agree to be bound by the Services Agreement, <a1>Privacy Policy</a1> (including its terms related to the processing of my <a2>sensitive data</a2>), and <a3>Cookie Policy</a3>.',
        },
        agreementNoName: {
          id: 'pages.servicesAgreementUpdate.agreementNoName',
          defaultMessage:
            'I hereby certify that the provided information is accurate and that I agree to be bound by the Services Agreement, <a1>Privacy Policy</a1> (including its terms related to the processing of my <a2>sensitive data</a2>), and <a3>Cookie Policy</a3>.',
        },
        button: {
          id: 'pages.servicesAgreementUpdate.button',
          defaultMessage: 'I agree',
        },
      }),
      alert: defineMessages({
        noGracePeriod: {
          id: 'pages.servicesAgreementUpdate.alert.noGracePeriod',
          defaultMessage:
            'You must review and accept the Services Agreement to continue going live on {product, select, oranum {Oranum} other {LiveJasmin}}.',
        },
        gracePeriod: {
          id: 'pages.servicesAgreementUpdate.alert.gracePeriod',
          defaultMessage:
            'To go live on {product, select, oranum {Oranum} other {LiveJasmin}}, please review and accept the Services Agreement within {numDays, plural, =1 {1 day} other {# days}}.',
        },
      }),
      seo: defineMessages({
        title: {
          id: 'pages.servicesAgreementUpdate.seo.title',
          defaultMessage: 'Service Agreement',
        },
      }),
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.servicesAgreementUpdate.navigationMenu.label',
            defaultMessage: 'Service Agreement',
          },
        }),
      },
    },
  },
};
