import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';
import product from 'utils/product';

import messages from '../awards.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.bestOf.awards.navigationMenu.label,
      link: () => link[id](),
      visibility$: authorization.getAuthorization$(id),
      parent: product.LiveJasmin ? 'bestOf' : 'topExperts',
      weight: 1,
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
